import axios from 'axios'
import type {
  AccountingType,
  AccountingFlat,
  Combo,
} from '@/types/accountingType'

export default class {
  static async getAccountings({
    page = 1,
    pageSize = 30,
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<AccountingType[]>('accountancies', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static async getAccountingById(id: string) {
    return axios.get<AccountingType>(`accountancies/${id}`)
  }

  static async putAccounting(accounting: AccountingFlat) {
    return axios.put<AccountingType>(
      `accountancies/${accounting.id}`,
      accounting,
    )
  }

  static async deleteAccounting(id: string) {
    return axios.delete(`accountancies/${id}`)
  }

  static async postAccounting(accounting: AccountingFlat) {
    return axios.post<AccountingType>(`accountancies`, accounting)
  }

  static async getCombos(municipalityId: string) {
    return axios.get<Combo[]>(`accountancies/combos/${municipalityId}`)
  }
}
