import type { MunicipalityType } from '@/types/municipalityType'
import type { SupplierType } from '@/types/supplierType'
import type { RoleType } from '@/types/roleType'
import { RolePermission } from '@/types/roleType'

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum sentryUserType {
  MUNICIPALITY = 'municipality',
  SUPPLIER = 'supplier',
}

export const sentryUserTypeTag = 'user_type'

export type UserType = {
  id: string
  email: string
  userStatus: UserStatus
  firstname?: string
  lastname?: string
  profileImage?: string
  municipalities?: Array<MunicipalityType>
  suppliers?: Array<SupplierType>
  organisation?: MunicipalityType['name'] | SupplierType['name']
  permissions: Array<RolePermission>
  roles: Array<RoleType>
  unreadChatMessages: number
  unreadTasks: number
}

export type UserTypeFlat = Omit<
  UserType,
  'municipalities' | 'suppliers' | 'permissions' | 'roles'
> & {
  municipalities?: Array<string>
  suppliers?: Array<string>
  roles: Array<string>
}
