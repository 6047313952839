<template>
  <CommonSuspenseWithToast>
    <header class="header">
      <div
        class="header__side-drawer-mask"
        :class="{ 'header__side-drawer-mask--active': sideDrawerMaskActive }"
        @click="
          () => {
            sideDrawerMaskActive = false
            searchOverlayActive = false
            headerKnowledgeBaseRef.knowledgeBaseVisible = false
          }
        "
      />

      <div class="header__wrap">
        <div class="header__top">
          <router-link
            class="header__logo"
            :to="{ name: 'HomeDashboard' }"
          >
            {{ t('application.title') }}
          </router-link>

          <div class="header__search">
            <HeaderSearch
              @toggle-search-active="(e) => (sideDrawerMaskActive = e)"
            />
          </div>

          <div class="header__nav">
            <HeaderNav />
          </div>
        </div>

        <div class="header__bottom">
          <WaimoIconButton
            v-if="isShareSupported"
            :type="IconButtonType.IconOnly"
            :color="Colors.Light"
            icon="/icons/01-Interface Essential/28-Share/share.svg"
            @click="
              share({
                title: title ?? undefined,
                text: title ?? undefined,
                url: router.currentRoute.value.fullPath,
              })
            "
          />

          <HeaderKnowledgeBase
            ref="headerKnowledgeBaseRef"
            @toggle-knowledgebase-active="(e) => (sideDrawerMaskActive = e)"
          />

          <!-- <WaimoIconButton
            :type="IconButtonType.IconOnly"
            :color="Colors.Light"
            :icon="
              privacyMode
                ? '/icons/01-Interface Essential/24-View/view-off.svg'
                : '/icons/01-Interface Essential/24-View/view-1.svg'
            "
            @click="togglePrivacy"
          /> -->
          <HeaderUser />
        </div>
      </div>
    </header>
  </CommonSuspenseWithToast>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Colors, IconButtonType, WaimoIconButton } from '@finetic/waimo-ui'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useShare, useTitle } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import HeaderNav from '@/components/ui/header/HeaderNav.vue'
import HeaderSearch from '@/components/ui/header/HeaderSearch.vue'
import HeaderUser from '@/components/ui/header/HeaderUser.vue'
import HeaderKnowledgeBase from '@/components/ui/header/HeaderKnowledgeBase.vue'
import CommonSuspenseWithToast from '@/components/ui/common/CommonSuspenseWithToast.vue'
import { useGeneralStore } from '@/stores/general'

const router = useRouter()
const title = useTitle()
const { t } = useI18n()

const generalStore = useGeneralStore()
const { searchOverlayActive } = storeToRefs(generalStore)

// const privacyMode = ref(false)

// const togglePrivacy = () => {
//   privacyMode.value = !privacyMode.value
//   privacyMode.value
//     ? document.documentElement.classList.add('privacy-mode')
//     : document.documentElement.classList.remove('privacy-mode')
// }

const { share, isSupported: isShareSupported } = useShare()

const sideDrawerMaskActive = ref(false)

const headerKnowledgeBaseRef = ref()
</script>

<style scoped lang="scss">
.header {
  position: relative;
  width: 7.2rem;
  height: 100%;
  background-color: var(--color-background-200);
  padding: 1.6rem 0;
  z-index: 1001; // +1 on WaimoSideDrawer :)

  &__side-drawer-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 1;
    background-color: color-mix(in srgb, var(--color-dark) 20%, transparent);

    &--active {
      height: 100%;
    }
  }

  &__wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 1.2rem;
    position: relative;
  }

  &__top,
  &__bottom {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    background-image: url(@/assets/img/logo-1.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    cursor: pointer;
    color: transparent;
    overflow: hidden;
  }
}
</style>
