import axios from 'axios'
import app from '@/main'
import qs from 'qs'
import { useGeneralStore } from '@/stores/general'
import { EntityTypeOf } from '@/types/types'

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'

axios.defaults.paramsSerializer = (p) =>
  qs.stringify(p, { arrayFormat: 'brackets' })

axios.interceptors.request.use(
  (request) => {
    // Add token in header
    if (app.config.globalProperties.$keycloak.authenticated) {
      request.headers.Authorization = `Bearer ${app.config.globalProperties.$keycloak.token}`
    }
    return request
  },
  (error) => {
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response) => {
    // Edit response config
    return response
  },
  (error) => {
    if (error.response) {
      // 400
      if (error.response.status >= 400 && error.response.status < 500) {
        // Hide 403 errors on GET task or GET chat (no permissions check on mercure notification)
        if (
          error.response.status === 403 &&
          (error.request.responseURL.toString().includes(EntityTypeOf.TASK) ||
            error.request.responseURL.toString().includes(EntityTypeOf.CHAT))
        ) {
          return Promise.resolve(false)
        }

        const errorMessage = {
          title: `Network error: ${error.response.data.status} - ${error.response.data.detail}`,
          message: `On request: ${error.request.responseURL}`,
        }

        useGeneralStore().setNetworkError(errorMessage)

        return Promise.resolve(false)
      }
    }
    return Promise.reject(error)
  },
)
