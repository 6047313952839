import { defineStore } from 'pinia'
import type { UserType, UserTypeFlat } from '@/types/userType'
import UserService from '@/services/UserService'
import { sentryUserType, sentryUserTypeTag, UserStatus } from '@/types/userType'
import * as Sentry from '@sentry/vue'

export const useUserStore = defineStore({
  id: 'users',
  state: (): {
    users: Map<string, UserType & { timestamp?: number, outdated?: boolean }>
    page: number
    me: UserType
    filters?: Record<string, any>
    sortBy?: Record<string, 'ASC' | 'DESC'>
    currentUsers: Map<UserType['id'], UserType & { entity?: string | null }>
    currentEntityDeletedWarning: boolean
  } => ({
    users: new Map<string, UserType>(),
    page: 1,
    filters: undefined,
    sortBy: undefined,
    me: {
      id: '',
      email: '',
      userStatus: UserStatus.INACTIVE,
      roles: [],
      permissions: [],
      unreadChatMessages: 0,
      unreadTasks: 0,
    },
    currentUsers: new Map<UserType['id'], UserType>(),
    currentEntityDeletedWarning: false,
  }),
  actions: {
    async fetchNextPage(clear?: boolean) {
      if (clear) this.page = 1

      return UserService.getUsers({
        page: this.page++,
        filters: this.filters,
        order: this.sortBy,
      })
        .then((res) => {
          if (clear) this.users.clear()
          return res
        })
        .then(({ data }) =>
          data.forEach((x) =>
            this.users.set(
              x.id,
              // 💅
              x.email.toLowerCase() === 'bram@finetic.nl'
                ? { ...x, profileImage: '/barbie.jpg' }
                : x,
            ),
          ),
        )
    },

    async getAllUsers() {
      return (await UserService.getUsers({ page: 1, pageSize: 999 })).data
    },

    async setFilters(filters: Record<string, any> = {}) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.filters = { ...filters }

        await this.fetchNextPage(true)
      }
    },

    async setSorting(sortBy?: { field: string, direction: 'ASC' | 'DESC' }) {
      if (sortBy !== this.sortBy) {
        this.sortBy = sortBy ? { [sortBy.field]: sortBy.direction } : undefined

        await this.fetchNextPage(true)
      }
    },

    async addNewUser(user: UserTypeFlat) {
      const { data } = await UserService.postUser(user)

      this.users.set(user.id, data)

      return data
    },

    async updateUser(user: UserTypeFlat) {
      const { data } = await UserService.putUser(user)

      this.users.set(user.id, data)

      if (this.me.id === data.id) await this.fetchMe()

      return data
    },

    async deleteUser(userId: string) {
      await UserService.deleteUser(userId)

      this.users.delete(userId)
    },

    async fetchUserById(id: string, cached = true) {
      const local = this.users.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } = await UserService.getUserById(id)

        // 👋
        if (data.email.toLowerCase() === 'bram@finetic.nl')
          data.profileImage = '/barbie.jpg'

        this.users.set(id, {
          ...(local?.outdated ? local : {}),
          ...data,
          timestamp: new Date().getTime(),
          outdated: false,
        })

        return data
      }

      return local
    },

    async markUserAsOutdated(id: string, watching: boolean) {
      const local = this.users.get(id)

      if (local?.timestamp) {
        local.outdated = true

        if (watching) this.fetchUserById(id, false)
      }
    },

    /**
      Set current entity ID of me
     * @param { string | undefined } newEntityId - ID of new current entity user is looking at.
     * We re-run this function every 30 minutes to let backend know we're still online. Otherwise we'll get removed from cache.
     */
    setCurrentEntityId(newEntityId: string | undefined) {
      const x = async () => {
        await UserService.postCurrentEntity(newEntityId)

        const current = this.currentUsers.get(this.me.id)

        if (current) {
          this.currentUsers.set(this.me.id, {
            ...current,
            entity: newEntityId ?? undefined,
          })
        }
      }
      x()
      setInterval(() => x(), 1000 * 60 * 30)
    },

    /**
     * Get ID's of all users' current entities. This is only called on initial app load.
     * Afterwards we update the `currentUserEntityIds`-record via Mercure-updates in `updateCurrentUserEntityIds`-function below.
     * We re-run this function every 30 minutes to get a new full list of current whereabouts of users to account for users who stopped using the app.
     */
    getCurrentUserEntityIds() {
      const x = async () => {
        const { data } = await UserService.fetchCurrentUserEntities()

        if (data.length) {
          data.forEach((x) => {
            this.currentUsers.set(x.id, x)
          })
        }
      }
      x()
      setInterval(() => x(), 1000 * 60 * 30)
    },

    /**
      Acting on Mercure-notification. Set current entity ID of user (ID).
     * @param { UserType & { entity: string | null }} data - user that just navigated to a new page.
     */
    updateCurrentUserEntityIds(data: UserType & { entity: string | null }) {
      this.currentUsers.set(data.id, data)
    },

    /**
     * Show warning if a user is looking at an entity that another user just changed/deleted.
     * @param { boolean } bool - show warning
     */
    setCurrentEntityDeletedWarning(bool: boolean) {
      this.currentEntityDeletedWarning = bool
    },

    async fetchMe() {
      const { data } = await UserService.getMe()

      Sentry.setUser({ id: data.id })
      Sentry.setTag(
        sentryUserTypeTag,
        data.municipalities?.length
          ? sentryUserType.MUNICIPALITY
          : sentryUserType.SUPPLIER,
      )

      // :)
      if (data.email.toLowerCase() === 'bram@finetic.nl')
        data.profileImage = '/barbie.jpg'

      this.me = data

      return data
    },
  },
  getters: {
    // 👇 .map()
    dataAsArray: (state) =>
      Array.from<UserType>(state.users.values()).map((x) =>
        x.email.toLowerCase() === 'bram@finetic.nl'
          ? { ...x, profileImage: '/barbie.jpg' }
          : x,
      ),

    findOrThrowUserById: (state) => {
      return (id: string) => {
        const user = state.users?.get(id)

        if (!user) throw new Error('Not found')

        return user
      }
    },

    findMyCurrentEntityId: (state) =>
      state.currentUsers.get(state.me.id)?.entity,

    findUsersOnThisEntity: (state) =>
      Array.from(state.currentUsers.values())
        .filter(
          (x) =>
            x.entity === state.currentUsers.get(state.me.id)?.entity &&
            x.id !== state.me.id,
        )
        .map((x) => x),
  },
})
