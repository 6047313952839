import type { ProductCustomField } from '@/types/allocationType'
import type { ClientType } from '@/types/clientType'
import type { ProductType, AllocationProduct } from '@/types/productType'
import type { AllocationSupplier } from '@/types/supplierType'
import type { MunicipalityType } from '@/types/municipalityType'
import type { FileType } from '@/types/fileType'
import type { EntityRelation } from '@/types/types'

export type AllocationRequestType = {
  id: string
  client: Pick<
    ClientType,
    | 'id'
    | 'initials'
    | 'middlename'
    | 'lastname'
    | 'gender'
    | 'bsn'
    | 'birthDate'
  >
  product?: Omit<AllocationProduct, 'labels'> &
    Pick<ProductType, 'unit' | 'frequency'>
  supplier: Pick<AllocationSupplier, 'id' | 'name' | 'agbCode'>
  municipality: Pick<MunicipalityType, 'id' | 'name' | 'wmoCode'>
  allocation?: {
    id: string
  }
  allocationRequestStatus: AllocationRequestStatus
  AllocationRequestDenyReason?: AllocationRequestDenyReason
  size: number
  since: string
  until?: string
  files?: Array<FileType>
  productCustomFields?: ProductCustomField[]
  relations?: EntityRelation[]
}

export type AllocationRequestDTO = Pick<
  AllocationRequestType,
  'since' | 'until' | 'productCustomFields'
> & {
  product: ProductType['id']
  supplier: AllocationSupplier['id']
  volume: number
  files?: Array<string>
}

export type AllocationRequestDenyType = AllocationRequestDTO &
  Pick<
    AllocationRequestType,
    'id' | 'allocationRequestStatus' | 'AllocationRequestDenyReason'
  >

export enum AllocationRequestStatus {
  REQUESTED = 'requested',
  APPROVED = 'approved',
  DENIED = 'denied',
}

export enum AllocationRequestDenyReason {
  NEW_ALLOCATION = 'new_allocation',
  OUT_OF_BUDGET = 'out_of_budget',
  NO_CONTRACT = 'no_contract',
  OUTSIDE_OF_CONTRACT = 'outside_of_contract',
  DIFFERENT_MUNICIPALITY = 'different_municipality',
  STACKING = 'stacking',
  HEALTHCARE_SPECIFIC = 'healthcare_specific',
  RESIDENCE_PRINCIPLE = 'residence_principle',
  AGE_BARRIER_REACHED = 'age_barrier_reached',
}
