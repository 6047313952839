import axios from 'axios'
import type { ComplaintType, ComplaintPost } from '@/types/complaintType'

export default class {
  static async getComplaints({
    page = 1,
    pageSize = 30,
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<ComplaintType[]>('complaints', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static async getComplaintById(id: string) {
    return axios.get<ComplaintType>(`complaints/${id}`)
  }

  static async postComplaint(complaint: ComplaintPost) {
    return axios.post<ComplaintType>('complaints', complaint)
  }

  static async putComplaint(complaint: ComplaintType) {
    return axios.put<ComplaintType>(`complaints/${complaint.id}`, complaint)
  }
}
