import { type ComposerTranslation, useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/users'
import type { ClientType } from '@/types/clientType'
import type { ChatMessage, ChatUserType } from '@/types/chatType'
import type { AllocationType } from '@/types/allocationType'
import type { ProductSubgroup } from '@/types/productType'

/**
 * Calculates the distance between the given date and the current date and time and returns the distance with a single letter unit.
 *
 * @param {string} date - The date to calculate the distance to, in ISO format.
 * @returns {string} - The distance between the given date and the current date and time, formatted as a short string.
 */
export const dateDistanceToNow = (date: string): string => {
  // Note: would be pretty easy to extend this util by adding functionality to have unitDisplay and the regex to remove whitespace as option

  const msPerYear = 1000 * 60 * 60 * 24 * 365
  const msPerMonth = 1000 * 60 * 60 * 24 * 29
  const msPerDay = 1000 * 60 * 60 * 23
  const msPerHour = 1000 * 60 * 60
  const msPerMinute = 1000 * 60
  const msPerSecond = 1000

  // Get datetime for createdAt and today from ISO-string to remove timezone differences
  const dateToCheck = new Date(new Date(date).toISOString()).getTime()
  const today = new Date(new Date().toISOString()).getTime()

  // Get difference between today and createdAt in ms
  const difference = Math.floor(today - dateToCheck)

  // Get short string containing value and unit. Gets called in switch below.
  const getDateString = (unit: string, ms: number) => {
    return Intl.NumberFormat(useI18n().locale.value, {
      style: 'unit',
      unit: unit,
      unitDisplay: 'narrow',
    })
      .format(Math.floor(difference / ms))
      .replace(/\s/g, '')
  }

  switch (true) {
    case difference > msPerYear:
      return getDateString('year', msPerYear)
    case difference > msPerMonth:
      return getDateString('month', msPerMonth)
    case difference > msPerDay:
      return getDateString('day', msPerDay)
    case difference > msPerHour:
      return getDateString('hour', msPerHour)
    case difference > msPerMinute:
      return getDateString('minute', msPerMinute)
    case difference >= msPerSecond - 5000:
      return getDateString('second', msPerSecond)
  }

  // Return empty string if difference is negative.
  // Because that means dateToCheck is too far in the future, and should not happen in this application.
  return ''
}

export const formatFullname = (
  t: ComposerTranslation,
  client: Pick<ClientType, 'gender' | 'initials' | 'middlename' | 'lastname'>,
) => {
  return t('entities.client.fullname', {
    salutation: t(`entities.client.salutation.${client.gender}`),
    firstname: client.initials,
    middlename: client.middlename,
    lastname: client.lastname,
  })
}

export const formatAllocationScope = (
  t: ComposerTranslation,
  n: ComposerTranslation,
  allocation: AllocationType,
) => {
  return t('entities.allocation.header.scope.header', {
    value:
      allocation.product.unit === 'euro'
        ? n(allocation.size ?? 0, 'currency')
        : n(allocation.size ?? 0, 'decimal'),
    unit: t(
      allocation.product.unit === 'euro'
        ? ''
        : `entities.allocation.header.scope.unit.${allocation.product.unit}`,
      allocation.size,
    ).toLowerCase(),
    frequency: t(
      `entities.allocation.header.scope.frequency.${allocation.product.frequency}`,
    ).toLowerCase(),
  })
}

export const formatProductLocation = (
  t: ComposerTranslation,
  subgroups: ProductSubgroup[],
) => {
  const subgroupFormatted =
    subgroups.length > 1
      ? `${subgroups[0].name} + ${t('entities.product.subgroupCount', subgroups.length - 1)}`
      : subgroups[0].name

  return `${subgroups[0].productGroup.name} | ${subgroupFormatted}`
}

/**
 * Takes the CSS color variable name and returns its hex value. This makes is possible to use CSS color variables in JS as well.
 *
 * @param {string} color - The name of the color variable. E.g. `--color-primary`.
 * @returns {string} - Hex value of the provided color
 */
export const getColor = (color: string): string => {
  let computedStyle: string = getComputedStyle(document.body).getPropertyValue(
    color,
  )

  if (computedStyle.includes('rgb')) {
    // Convert string `rbg(val, val, val)` to array with values as numbers
    // Since v1.79 Sass no longer rounds RGB channels to the nearest integer, so we have to round it ourselves.
    const rgb: Array<number> = computedStyle
      .substring(4, computedStyle.length - 1)
      .replace(/ /g, '')
      .split(',')
      .map((x) => Math.round(Number(x)))

    // Convert each rgb number value to hex string (e.g. 255 to `ff`)
    const componentToHex = (c: number): string => {
      const hex = c.toString(16)
      return hex.length === 1 ? `0${hex}` : hex
    }

    // Run each rgb value through converter, remove commas and convert to string
    computedStyle = `#${rgb.map((c) => componentToHex(c))}`.replace(/,/g, '')
  }

  return computedStyle
}

/**
 * Check if chat has been read by current use
 *
 * @param {ChatMessage} lastMessage - the last message from the chat
 * @param {ChatUserType[]} users - array of users who have access to this chat
 */
export const checkIfChatHasUnreadMessages = async (
  lastMessage: ChatMessage,
  users: ChatUserType[],
) => {
  const lastMessageTime = new Date(lastMessage.timestamp).getTime()

  let user: ChatUserType | undefined

  if (users.length === 1) {
    user = users[0]
  } else {
    const me = await useUserStore().fetchMe()
    user = users.find((x) => x.id === me.id)
  }

  const lastVisitTime = new Date(user?.lastVisitTime ?? 0).getTime()

  return lastMessageTime > lastVisitTime ? true : false
}
