import type { AllocationType } from '@/types/allocationType'
import type { ClientType } from '@/types/clientType'
import type { SupplierType } from '@/types/supplierType'
import type { ProductType } from '@/types/productType'
import type { EntityRelation } from '@/types/types'

export type ComplaintType = {
  id: string
  allocation: {
    id: AllocationType['id']
    name: string
    tnr: AllocationType['allocationNumber']
  }
  supplier: {
    id: SupplierType['id']
    name: string
  }
  product: {
    id: ProductType['id']
    name: string
  }
  complaintStatus: ComplaintStatus
  category: ComplaintCategory
  complaintDescription: string
  resolveDescription?: string
  addition?: string
  internalNotes?: string
  clientEmail?: ClientType['email']
  clientPhone?: ClientType['phone']
  contactInfoExplanation?: string
  createdAt: string
  inProgressAt?: string
  completedAt?: string
  relations?: EntityRelation[]
}

export type ComplaintPost = Omit<
  ComplaintType,
  | 'allocation'
  | 'supplier'
  | 'product'
  | 'resolveDescription'
  | 'inProgressAt'
  | 'completedAt'
> & {
  allocation: AllocationType['id']
}

export enum ComplaintStatus {
  SIGNAL = 'signal',
  RECEIVED_BY_COMPLAINTS_CENTER = 'received_by_complaints_center',
  FORWARDED_TO_SUPPLIER = 'forwarded_to_supplier',
  UNDER_REVIEW_BY_SUPPLIER = 'under_review_by_supplier',
  RESOLVED_BY_SUPPLIER = 'resolved_by_supplier',
  RESOLVED_SATISFIED = 'resolved_satisfied',
  RESOLVED_NOT_SATISFIED = 'resolved_not_satisfied',
  NOT_TAKEN_INTO_CONSIDERATION = 'not_taken_into_consideration',
}

export enum ComplaintCategory {
  FAILURE_TO_COMPLY_AGREEMENTS = 'failure_to_comply_agreements',
  TREATMENT = 'treatment',
  THEFT = 'theft',
  FINANCIAL = 'financial',
  NO_REPLACEMENT = 'no_replacement',
  HOURS = 'hours',
  FREQUENTLY_SICK = 'frequently_sick',
  OTHER = 'other',
}
