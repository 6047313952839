<template>
  <router-link
    class="menuitem"
    :to="props.to ?? ''"
  >
    <WaimoIcon
      :icon="icon"
      :size="Sizes.lg"
      :color="Colors.Light"
    />
    <span>{{ text }}</span>
  </router-link>
</template>

<script setup lang="ts">
import { Sizes, WaimoIcon, Colors } from '@finetic/waimo-ui'

const props = defineProps<{
  to: { name: string } | string
  icon: string
  text: string
}>()
</script>

<style scoped lang="scss">
.menuitem {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border-radius: 4px;
  background-color: transparent;
  color: var(--color-light);
  font-weight: 500;
  cursor: pointer;

  &.router-link-active {
    background-color: var(--color-primary);
  }

  &:hover {
    background-color: var(--color-primary-80);
    text-decoration: none;
  }

  &:active {
    outline: 1px solid var(--color-primary-70);
  }
}
</style>
