import { defineStore } from 'pinia'
import type { RoleType, RoleTypeFlat } from '@/types/roleType'
import RoleService from '@/services/RoleService'

export const useRoleStore = defineStore({
  id: 'roles',
  state: (): {
    data: Map<string, RoleType & { timestamp?: number, outdated?: boolean }>
    page: number
    filters?: Record<string, any>
    sortBy?: Record<string, 'ASC' | 'DESC'>
  } => ({
    data: new Map<string, RoleType>(),
    page: 1,
    filters: undefined,
    sortBy: undefined,
  }),
  actions: {
    async fetchNextPage(clear?: boolean) {
      if (clear) this.page = 1

      return RoleService.getRoles({
        page: this.page++,
        filters: this.filters,
        order: this.sortBy,
      })
        .then((res) => {
          if (clear) this.data.clear()
          return res
        })
        .then(({ data }) => data.forEach((x) => this.data.set(x.id, x)))
    },

    async fetchRoleById(id: string, cached = true) {
      const local = this.data.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } = await RoleService.getRoleById(id)

        this.data.set(id, {
          ...(local?.outdated ? local : {}),
          ...data,
          timestamp: new Date().getTime(),
          outdated: false,
        })

        return data
      }

      return local
    },

    async setFilters(filters: Record<string, any> = {}) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.filters = { ...filters }

        await this.fetchNextPage(true)
      }
    },

    async setSorting(sortBy?: { field: string, direction: 'ASC' | 'DESC' }) {
      if (sortBy !== this.sortBy) {
        this.sortBy = sortBy ? { [sortBy.field]: sortBy.direction } : undefined

        await this.fetchNextPage(true)
      }
    },

    async getAllRoles() {
      return (await RoleService.getRoles({ page: 1, pageSize: 999 })).data
    },

    async addNewRole(role: RoleTypeFlat) {
      const { data } = await RoleService.postRole(role)

      this.data.set(data.id, data)

      return data
    },

    async updateRole(role: RoleTypeFlat) {
      const { data } = await RoleService.putRole(role)

      this.data.set(role.id, data)

      return data
    },

    async deleteRole(roleId: string) {
      await RoleService.deleteRole(roleId)

      this.data.delete(roleId)
    },

    async markRoleAsOutdated(id: string, watching: boolean) {
      const local = this.data.get(id)

      if (local?.timestamp) {
        local.outdated = true

        if (watching) this.fetchRoleById(id, false)
      }
    },
  },
  getters: {
    dataAsArray: (state) => Array.from<RoleType>(state.data.values()),

    findOrThrowRoleById: (state) => {
      return (id: string) => {
        const role = state.data?.get(id)

        if (!role) throw new Error('Not found')

        return role
      }
    },
  },
})
