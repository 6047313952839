import { storeToRefs } from 'pinia'
import { useClientStore } from '@/stores/clients'
import { useAllocationStore } from '@/stores/allocations'
import { useAllocationRequestStore } from '@/stores/allocationRequests'
import { useQuotationStore } from '@/stores/quotations'
import { useComplaintStore } from '@/stores/complaints'
import { useSupplierStore } from '@/stores/suppliers'
import { useContractStore } from '@/stores/contracts'
import { useProductStore } from '@/stores/products'
import { useMunicipalityStore } from '@/stores/municipalities'
import { useClaimStore } from '@/stores/claims'
import { useFulfillmentStore } from '@/stores/fulfillments'
import { usePaymentStore } from '@/stores/payments'
import { useCreditorStore } from '@/stores/creditors'
import { useAccountingStore } from '@/stores/accountancies'
import { useIberichtStore } from '@/stores/iberichts'
import { useUserStore } from '@/stores/users'
import { useRoleStore } from '@/stores/roles'
import { useTaskStore } from '@/stores/tasks'
import { useChatStore } from '@/stores/chats'
import { EntityTypeOf, EntityAction } from '@/types/types'
import type { UserType } from '@/types/userType'

const baseUrl = import.meta.env.VITE_MERCURE_BASE_URL

/**
 * Subscribes to Mercure topic
 *
 * @param {string} topic - URL for topic
 * @returns {EventSource} - Published update, every time an update is published
 */
export const mercureSubscribe = (topic: string) => {
  const url = new URL(`${baseUrl}.well-known/mercure`)
  url.searchParams.append('topic', topic)

  return new EventSource(url)
}

/**
 * Unsubscribes from Mercure topic
 *
 * @param {EventSource} eventSource - The EventSource to unsubscribe from
 */
export const mercureUnsubscribe = (eventSource: EventSource) => {
  eventSource.close()
}

/**
 * Initialize all nessecary Mercure subscriptions. To be used in `main.ts`
 */
const initMercureSubscriptions = () => {
  const userCurrentEntity = mercureSubscribe('user/current_entity')
  userCurrentEntity.onmessage = (e) => {
    const data: UserType & { entity: string | null } = JSON.parse(e.data)

    const userStore = useUserStore()
    userStore.updateCurrentUserEntityIds(data)
  }

  /**
   * If a user is currently on an entity that has been changed, re-run api call to get new data.
   * If a user is currently on a task that is no longer active, redirect user to tasks overview
   */
  const entityChanged = mercureSubscribe('entity/changed')
  entityChanged.onmessage = (e) => {
    const data = JSON.parse(e.data)
    const action = data.action
    const type = data.type
    const changedId = data.id

    // Get user data
    const userStore = useUserStore()
    const { findMyCurrentEntityId } = storeToRefs(userStore)
    const currentEntityId = findMyCurrentEntityId.value

    // Init entity stores
    const clientStore = useClientStore()
    const allocationStore = useAllocationStore()
    const allocationRequestStore = useAllocationRequestStore()
    const quotationStore = useQuotationStore()
    const complaintStore = useComplaintStore()
    const supplierStore = useSupplierStore()
    const contractStore = useContractStore()
    const productStore = useProductStore()
    const municipalityStore = useMunicipalityStore()
    const claimStore = useClaimStore()
    const fulfillmentStore = useFulfillmentStore()
    const paymentStore = usePaymentStore()
    const creditorStore = useCreditorStore()
    const accountingStore = useAccountingStore()
    const iberichtStore = useIberichtStore()
    const roleStore = useRoleStore()
    const taskStore = useTaskStore()
    const chatStore = useChatStore()

    const getEntity = (type: EntityTypeOf, id: string): void => {
      switch (type) {
        case EntityTypeOf.CHAT:
          chatStore.fetchChatById(id, false, true)
          userStore.fetchMe()
          break
        case EntityTypeOf.TASK:
          taskStore.fetchTaskById(id, false)
          userStore.fetchMe()
          break
      }
    }

    const syncEntity = (
      type: EntityTypeOf,
      id: string,
      watching: boolean,
    ): void => {
      switch (type) {
        case EntityTypeOf.CLIENT:
          clientStore.markClientAsOutdated(id, watching)
          break
        case EntityTypeOf.ALLOCATION:
          allocationStore.markAllocationAsOutdated(id, watching)
          break
        case EntityTypeOf.ALLOCATION_REQUEST:
          allocationRequestStore.markAllocationRequestAsOutdated(id, watching)
          break
        case EntityTypeOf.QUOTATION:
          quotationStore.markQuotationAsOutdated(id, watching)
          break
        case EntityTypeOf.COMPLAINT:
          complaintStore.markComplaintAsOutdated(id, watching)
          break
        case EntityTypeOf.SUPPLIER:
          supplierStore.markSupplierAsOutdated(id, watching)
          break
        case EntityTypeOf.CONTRACT:
          contractStore.markContractAsOutdated(id, watching)
          break
        case EntityTypeOf.PRODUCT:
          productStore.markProductAsOutdated(id, watching)
          break
        case EntityTypeOf.MUNICIPALITY:
          municipalityStore.markMunicipalityAsOutdated(id, watching)
          break
        case EntityTypeOf.CLAIM:
          claimStore.markClaimAsOutdated(id, watching)
          break
        case EntityTypeOf.FULFILLMENT:
          fulfillmentStore.markFulfillmentAsOutdated(id, watching)
          break
        case EntityTypeOf.PAYMENT:
          paymentStore.fetchNextPage(true)
          break
        case EntityTypeOf.CREDITOR:
          creditorStore.markCreditorAsOutdated(id, watching)
          break
        case EntityTypeOf.ACCOUNTING:
          accountingStore.markAccountingAsOutdated(id, watching)
          break
        case EntityTypeOf.IBERICHTEN:
          iberichtStore.fetchNextDownloadsPage(true)
          break
        case EntityTypeOf.USER:
          userStore.markUserAsOutdated(id, watching)
          break
        case EntityTypeOf.ROLE:
          roleStore.markRoleAsOutdated(id, watching)
          break
        case EntityTypeOf.CHAT:
          chatStore.fetchNewChatMessagesByChatId(id, watching)
          userStore.fetchMe()
          break
      }
    }

    const deleteEntity = (
      type: EntityTypeOf,
      id: string,
      watching: boolean,
    ): void => {
      switch (type) {
        case EntityTypeOf.TASK:
          taskStore.removeTask(id)
          if (watching) userStore.setCurrentEntityDeletedWarning(true)
          break
      }
    }

    switch (action) {
      case EntityAction.CREATED:
        getEntity(type, changedId)
        break
      case EntityAction.UPDATED:
        syncEntity(type, changedId, changedId === currentEntityId)
        break
      case EntityAction.DELETED:
        deleteEntity(type, changedId, changedId === currentEntityId)
        break
    }
  }
}

export default initMercureSubscriptions
