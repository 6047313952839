import { defineStore } from 'pinia'
import ClaimService from '@/services/ClaimService'
import type { ClaimType } from '@/types/claimType'

export const useClaimStore = defineStore({
  id: 'claims',
  state: (): {
    claims: Map<string, ClaimType & { timestamp?: number, outdated?: boolean }>
    page: number
    filters?: Record<string, any>
    sortBy?: Record<string, 'ASC' | 'DESC'>
  } => ({
    claims: new Map<string, ClaimType>(),
    page: 1,
    filters: undefined,
    sortBy: {
      claimDate: 'DESC',
    },
  }),
  actions: {
    async fetchNextPage(clear?: boolean) {
      if (clear) this.page = 1

      return ClaimService.getClaims({
        page: this.page++,
        filters: {
          ...this.filters,
          until: this.filters?.until
            ? `${this.filters.until.year}-${(this.filters.until.month + 1).toString().padStart(2, '0')}-01`
            : undefined,
        },
        order: this.sortBy,
      })
        .then((res) => {
          if (clear) this.claims.clear()
          return res
        })
        .then(({ data }) => data.forEach((x) => this.claims.set(x.id, x)))
    },

    async setFilters(filters: Record<string, any> = {}) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.filters = { ...filters }

        await this.fetchNextPage(true)
      }
    },

    async setSorting(sortBy?: { field: string, direction: 'ASC' | 'DESC' }) {
      if (sortBy !== this.sortBy) {
        this.sortBy = sortBy ? { [sortBy.field]: sortBy.direction } : undefined

        await this.fetchNextPage(true)
      }
    },

    async fetchClaimById(id: string, cached = true) {
      const local = this.claims.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } = await ClaimService.getClaimById(id)

        if (data) {
          this.claims.set(id, {
            ...(local?.outdated ? local : {}),
            ...data,
            timestamp: new Date().getTime(),
            outdated: false,
          })
        }

        return data
      }

      return local
    },

    async markClaimAsOutdated(id: string, watching: boolean) {
      const local = this.claims.get(id)

      if (local?.timestamp) {
        local.outdated = true

        if (watching) this.fetchClaimById(id, false)
      }
    },
  },
  getters: {
    dataAsArray: (state) => Array.from<ClaimType>(state.claims.values()),

    findOrThrowClaimById: (state) => {
      return (claimId: string) => {
        const claim = state.claims?.get(claimId)

        if (!claim) throw new Error('Claim not found')

        return claim
      }
    },
  },
})
