import type { UserType } from '@/types/userType'
import { EntityTypeOf, type EntityRelation } from '@/types/types'

export enum TaskTypeOf {
  ALLOCATION_NEARING_EXPIRATION = 'allocation_nearing_expiration',
  ALLOCATION_RECEIVED = 'allocation_received',
  ALLOCATION_REQUEST_RECEIVED = 'allocation_request_received',
  ASSESS_ALLOCATION_REQUEST = 'assess_allocation_request',
  ASSESS_FULFILLMENT_BUDGET = 'assess_fulfillment_budget',
  ASSESS_FULFILLMENT_DEADLINE = 'assess_fulfillment_deadline',
  ASSESS_FULFILLMENT_EXCEEDED = 'assess_fulfillment_exceeded',
  COMPLAINT_FORWARDED_TO_SUPPLIER = 'complaint_forwarded_to_supplier',
  COMPLAINT_RECEIVED_BY_COMPLAINTS_CENTER = 'complaint_received_by_complaints_center',
  COMPLAINT_RESOLVED_BY_SUPPLIER = 'complaint_resolved_by_supplier',
  COMPLAINT_RESOLVED_NOT_SATISFIED = 'complaint_resolved_not_satisfied',
  END_DATE_RECEIVED = 'end_date_received',
  EVALUATE_CLAIM = 'evaluate_claim',
  FULFILLMENT_APPROVED = 'fulfillment_approved',
  FULFILLMENT_CREDIT_EXPECTED = 'fulfillment_credit_expected',
  FULFILLMENT_IN_PROGRESS = 'fulfillment_in_progress',
  FULFILLMENT_REJECTED = 'fulfillment_rejected',
  MISSING_CREDITOR_NUMBER = 'missing_creditor_number',
  NEW_USER = 'new_user',
  QUOTATION_ADDED = 'quotation_added',
  QUOTATION_APPROVED = 'quotation_approved',
  QUOTATION_REJECTED = 'quotation_rejected',
  QUOTATION_REQUESTED = 'quotation_requested',
  START_DATE_RECEIVED = 'start_date_received',
  SUPPLIER_FULFILLMENT_CREDIT_EXPECTED = 'supplier_fulfillment_credit_expected',
}

export enum TaskDescriptionContext {
  PRODUCT_NAME = 'product_name',
  SUPPLIER_NAME = 'supplier_name',
  USERNAME = 'username',
  ORGANISATION = 'organisation',
  ALLOCATION_NUMBER = 'allocation_number',
  FULFILLMENT_NUMBER = 'fulfillment_number',
  MUNICIPALITY_NAME = 'municipality_name',
}

export type TaskDescription = {
  context: TaskDescriptionContext
  values: {
    client?: string
    supplier?: string
    product?: string
    allocation?: string
    email?: string
    organisation?: string
    allocation_number?: number
    fulfillment_number?: string
    municipality?: string
    municipality_name?: string
    start_date?: string
    supplier_start_date?: string
    end_date?: string
    supplier_end_date?: string
    budgetTotal?: number
    amount?: number
    percentage?: number
    month_year?: string
    month?: string
    year?: string
    reason?: string
    size?: number
  }
}

export type TaskType = {
  id: string
  type: TaskTypeOf
  createdAt: string
  createdBy: UserType['id' | 'firstname' | 'lastname']
  read: boolean
  priority: boolean
  subjectId: string
  subjectName: string
  subjectType: EntityTypeOf
  titleDescriptions?: TaskDescription[]
  taskText: TaskDescription
  relations: EntityRelation[]
}

export type ListTypeTask = Record<string, TaskType>
