import axios from 'axios'
import type { PaymentType, PaymentHistoryType } from '@/types/paymentType'
import { AccountingSoftware } from '@/types/municipalityType'
import type { FileType } from '@/types/fileType'

export default class {
  static async getPayments({
    page = 1,
    pageSize = 30,
  }: {
    page?: number
    pageSize?: number
  } = {}) {
    return axios.get<PaymentType[]>('accountancies/payment', {
      params: { page, itemsPerPage: pageSize },
    })
  }

  static async getDownloadHistory({
    page = 1,
    pageSize = 30,
  }: {
    page?: number
    pageSize?: number
  } = {}) {
    return axios.get<PaymentHistoryType[]>('accountancies/payment_history', {
      params: { page, itemsPerPage: pageSize },
    })
  }

  static async getPaymentByMunicipalityAndSoftware(
    municipalityId: string,
    accountingSoftware: AccountingSoftware,
  ) {
    return axios.get<FileType>(
      `accountancies/payment/${municipalityId}/${accountingSoftware}`,
    )
  }
}
