import axios from 'axios'
import type {
  FulfillmentType,
  FloatingFulfillmentPut,
} from '@/types/fulfillmentType'
import type { EntityTypeOf } from '@/types/types'

export default class {
  static async getFulfillmentsByEntityAndId(
    entityType: EntityTypeOf,
    entityId: string,
    page: number,
  ) {
    return axios.get<FulfillmentType[]>('fulfillments', {
      params: { [entityType]: entityId, page: page },
    })
  }

  static async getFulfillmentById(id: string) {
    return axios.get<FulfillmentType>(`fulfillments/${id}`)
  }

  static async putFulfillment(fulfillment: FulfillmentType) {
    return axios.put<FulfillmentType>(
      `fulfillments/${fulfillment.id}`,
      fulfillment,
    )
  }

  static async putFloatingFulfillment(
    floatingFulfillment: FloatingFulfillmentPut,
  ) {
    return axios.put<FulfillmentType>(
      `floating_status/${floatingFulfillment.id}`,
      floatingFulfillment,
    )
  }
}
