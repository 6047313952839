import { defineStore } from 'pinia'
import IberichtService from '@/services/IberichtService'
import type {
  IberichtHistory,
  IberichtType,
  IberichtTypeOf,
} from '@/types/iberichtType'

export const useIberichtStore = defineStore({
  id: 'iberichts',
  state: (): {
    downloads: Map<number, IberichtType & { timestamp?: number }>
    history: Map<number, IberichtHistory & { timestamp?: number }>

    downloadFilters?: Record<string, any>
    historyFilters?: Record<string, any>

    downloadPage: number
    historyPage: number
  } => ({
    downloads: new Map<number, IberichtType>(),
    history: new Map<number, IberichtHistory>(),

    downloadFilters: undefined,
    historyFilters: undefined,

    downloadPage: 1,
    historyPage: 1,
  }),
  actions: {
    async fetchNextDownloadsPage(clear?: boolean) {
      if (clear) this.downloadPage = 1

      return IberichtService.getIberichts({
        page: this.downloadPage++,
        filters: this.downloadFilters,
      })
        .then((res) => {
          if (clear) this.downloads.clear()
          return res
        })
        .then(({ data }) => data.forEach((x, i) => this.downloads.set(i, x)))
    },

    async fetchNextDownloadHistoryPage(clear?: boolean) {
      if (clear) this.historyPage = 1

      return IberichtService.getDownloadHistory({
        page: this.historyPage++,
        filters: this.historyFilters,
      })
        .then((res) => {
          if (clear) this.history.clear()
          return res
        })
        .then(({ data }) => data.forEach((x, i) => this.history.set(i, x)))
    },

    async setFilters(filters: Record<string, any> = {}, history?: boolean) {
      const filtersToSet = history ? this.historyFilters : this.downloadFilters

      if (JSON.stringify(filters) !== JSON.stringify(filtersToSet)) {
        if (history) {
          this.historyFilters = { ...filters }
          await this.fetchNextDownloadHistoryPage(true)
        } else {
          this.downloadFilters = { ...filters }
          await this.fetchNextDownloadsPage(true)
        }
      }
    },

    async fetchIberichtByMunicipalityAndType(
      municipalityId: string,
      type: IberichtTypeOf,
    ) {
      const { data } = await IberichtService.getIberichtByMunicipalityAndType(
        municipalityId,
        type,
      )

      for (const [k, v] of this.downloads.entries()) {
        if (v.municipality.id === municipalityId && v.type === type) {
          this.downloads.delete(k)
        }
      }

      return data
    },
  },
  getters: {
    downloadsAsArray: (state) =>
      Array.from<IberichtType>(state.downloads.values()),

    downloadHistoryAsArray: (state) =>
      Array.from<IberichtHistory>(state.history.values()),
  },
})
