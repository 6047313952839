import axios from 'axios'
import type {
  CustomField,
  CustomFieldDTO,
  ProductGroup,
  ProductPrice,
  ProductSubgroup,
  ProductSubgroupFlat,
  ProductTreeProducts,
  ProductTreeSubGroups,
  ProductType,
  ProductTypeFlat,
} from '@/types/productType'

export default class {
  /**
   * Products
   */

  static postProduct(product: Omit<ProductTypeFlat, 'id'>) {
    return axios.post<ProductType>(`products`, product)
  }

  static getProducts({
    page = 1,
    pageSize = 30,
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<ProductType[]>('products', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static getProductById(id: string) {
    return axios.get<ProductType>(`products/${id}`)
  }

  static async getProductTreeProducts() {
    return axios.get<ProductTreeProducts[]>(`product_tree/products`)
  }

  static async getProductTreeSubgroups() {
    return axios.get<ProductTreeSubGroups[]>(`product_tree/subgroups`)
  }

  static putProduct(product: ProductTypeFlat) {
    return axios.put<ProductType>(`products/${product.id}`, product)
  }

  static async deleteProduct(productId: string) {
    return axios.delete(`products/${productId}`)
  }

  /**
   * Productgroups
   */

  static postProductGroup(productGroup: Omit<ProductGroup, 'id'>) {
    return axios.post<ProductGroup>('product_groups', productGroup)
  }

  static getProductGroups({
    filters = undefined,
    order = undefined,
  }: {
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<ProductSubgroup[]>('product_groups', {
      params: { ...filters, order, pagination: false },
    })
  }

  static async getProductGroupById(id: string) {
    return axios.get<ProductGroup>(`product_groups/${id}`)
  }

  static async putProductGroup(productGroup: ProductGroup) {
    return axios.put<ProductGroup>(
      `product_groups/${productGroup.id}`,
      productGroup,
    )
  }

  static async deleteProductGroup(productGroupId: string) {
    return axios.delete(`product_groups/${productGroupId}`)
  }

  /**
   * Productsubgroups
   */

  static postProductSubgroup(productSubgroup: Omit<ProductSubgroupFlat, 'id'>) {
    return axios.post<ProductSubgroup>('product_subgroups', productSubgroup)
  }

  static getProductSubgroups(args?: { productGroup?: string }) {
    return axios.get<ProductSubgroup[]>('product_subgroups', {
      params: { pagination: false, ...args },
    })
  }

  static getProductSubgroupById(id: string) {
    return axios.get<ProductSubgroup>(`product_subgroups/${id}`)
  }

  static putProductSubgroup(productSubgroup: ProductSubgroupFlat) {
    return axios.put<ProductSubgroup>(
      `product_subgroups/${productSubgroup.id}`,
      productSubgroup,
    )
  }

  static async deleteProductSubgroup(productSubgroupId: string) {
    return axios.delete(`product_subgroups/${productSubgroupId}`)
  }

  /**
   * Prices
   */

  static postProductPrice(productId: string, price: ProductPrice) {
    return axios.post<ProductPrice>(`products/${productId}/prices`, {
      ...price,
      price: price.price * 100,
    })
  }

  static async getPricesByProductId(productId: string) {
    return axios.get<ProductPrice[]>(`products/${productId}/prices`)
  }

  static async getPriceById(productId: string, priceId: string) {
    return axios.get<ProductPrice>(`products/${productId}/prices/${priceId}`)
  }

  static putProductPrice(productId: string, price: ProductPrice) {
    return axios.put<ProductPrice>(`products/${productId}/prices/${price.id}`, {
      ...price,
      price: price.price * 100,
    })
  }

  static async deleteProductPrice(productId: string, priceId: string) {
    return axios.delete(`products/${productId}/prices/${priceId}`)
  }

  /**
   * CustomFields
   */

  static postProductCustomField(productId: string, field: CustomFieldDTO) {
    return axios.post<CustomField>(`products/${productId}/custom_fields`, field)
  }

  static async getCustomFieldsByProductId(productId: string) {
    return axios.get<CustomField[]>(`products/${productId}/custom_fields`, {
      params: { pagination: false },
    })
  }

  static async getCustomFieldById(productId: string, customFieldId: string) {
    return axios.get<CustomField>(
      `products/${productId}/custom_fields/${customFieldId}`,
    )
  }

  static async putCustomField(productId: string, field: CustomField) {
    return axios.put<CustomField>(
      `products/${productId}/custom_fields/${field.id}`,
      field,
    )
  }
}
