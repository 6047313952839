<template>
  <div class="list-navigation">
    <Section
      v-if="
        isAuthorized([
          RolePermission.CLIENT_READ,
          RolePermission.ALLOCATION_READ,
          RolePermission.ALLOCATION_REQUEST_READ,
          RolePermission.SUPPLIER_READ,
          RolePermission.CONTRACT_READ,
          RolePermission.PRODUCT_READ,
          RolePermission.PRODUCT_GROUP_READ,
          RolePermission.MUNICIPALITY_READ,
        ])
      "
      :text="t('lists.navigation.sections.lists')"
      :offset="12"
    >
      <div class="list-navigation__items">
        <MenuItem
          v-if="isAuthorized(RolePermission.CLIENT_READ)"
          :to="{ name: 'ClientList' }"
          icon="/icons/17-Users/10-Geomertic-Close Up-Single User-Neutral/single-neutral-profile-picture.svg"
          :text="t('lists.navigation.items.clients', 2)"
        />
        <MenuItem
          v-if="isAuthorized(RolePermission.ALLOCATION_READ)"
          :to="{ name: 'AllocationList' }"
          icon="/icons/06-Business-Products/02-Business-Deals/business-contract-give.svg"
          :text="t('lists.navigation.items.allocations', 2)"
        />
        <MenuItem
          v-if="isAuthorized(RolePermission.ALLOCATION_REQUEST_READ)"
          :to="{ name: 'AllocationRequestList' }"
          icon="/icons/17-Users/13-Geomertic-Close Up-Single User Actions-Neutral/single-neutral-actions-laptop.svg"
          :text="t('lists.navigation.items.allocationRequests', 2)"
        />
        <MenuItem
          v-if="isAuthorized(RolePermission.QUOTATION_READ)"
          :to="{ name: 'QuotationList' }"
          icon="/icons/07-Work-Office-Companies/09-Human-Resources/human-resources-offer-man-1.svg"
          :text="t('lists.navigation.items.quotations', 2)"
        />
        <MenuItem
          v-if="isAuthorized(RolePermission.COMPLAINT_READ)"
          :to="{ name: 'ComplaintList' }"
          icon="/icons/21-Messages-Chat-Smileys/05-Smileys/smiley-angry.svg"
          :text="t('lists.navigation.items.complaints', 2)"
        />
        <MenuItem
          v-if="isAuthorized(RolePermission.SUPPLIER_READ)"
          :to="{ name: 'SupplierList' }"
          icon="/icons/07-Work-Office-Companies/02-Meetings-Collaboration/presentation.svg"
          :text="t('lists.navigation.items.suppliers', 2)"
        />
        <MenuItem
          v-if="isAuthorized(RolePermission.CONTRACT_READ)"
          :to="{ name: 'ContractList' }"
          icon="/icons/06-Business-Products/02-Business-Deals/business-deal-handshake.svg"
          :text="t('lists.navigation.items.contracts', 2)"
        />
        <MenuItem
          v-if="isAuthorized(RolePermission.PRODUCT_READ)"
          :to="{ name: 'ProductList' }"
          icon="/icons/44-Entertainment-Event-Hobbies/13-Farmer-Market/farmer's-market-vendor.svg"
          :text="t('lists.navigation.items.products', 2)"
        />
        <MenuItem
          v-if="isAuthorized(RolePermission.MUNICIPALITY_READ)"
          :to="{ name: 'MunicipalityList' }"
          icon="/icons/50-Landmarks/01-Official Buildings/official-building-2.svg"
          :text="t('lists.navigation.items.municipalities', 2)"
        />
      </div>
    </Section>

    <Section
      v-if="
        isAuthorized([RolePermission.CLAIM_READ, RolePermission.PAYMENT_READ])
      "
      :text="t('lists.navigation.sections.financial')"
      :offset="12"
    >
      <div class="list-navigation__items">
        <MenuItem
          v-if="isAuthorized(RolePermission.CLAIM_READ)"
          :to="{ name: 'ClaimList' }"
          icon="/icons/08-Money-Payments-Finance/01-Accounting-Billing/accounting-invoice.svg"
          :text="t('lists.navigation.items.claims', 2)"
        />
        <MenuItem
          v-if="isAuthorized(RolePermission.PAYMENT_READ)"
          :to="{ name: 'PaymentView' }"
          icon="/icons/08-Money-Payments-Finance/02-Money/accounting-coins-stack-1.svg"
          :text="t('lists.navigation.items.payments', 2)"
        />
      </div>
    </Section>

    <Section
      v-if="
        isAuthorized([
          RolePermission.I_BERICHT_READ,
          RolePermission.USER_READ,
          RolePermission.ROLE_MANAGEMENT_READ,
        ])
      "
      :text="t('lists.navigation.sections.administration')"
      :offset="12"
    >
      <div class="list-navigation__items">
        <MenuItem
          v-if="isAuthorized(RolePermission.I_BERICHT_READ)"
          :to="{ name: 'Ibericht' }"
          icon="/icons/04-Programing-Apps-Websites/01-Programing/programming-hold-code.svg"
          :text="t('lists.navigation.items.iberichten', 2)"
        />
        <MenuItem
          v-if="isAuthorized(RolePermission.USER_READ)"
          :to="{ name: 'UserList' }"
          icon="/icons/17-Users/02-Geometric-Full Body-Single User-Neutral/single-neutral.svg"
          :text="t('lists.navigation.items.users', 2)"
        />
        <MenuItem
          v-if="isAuthorized(RolePermission.ROLE_MANAGEMENT_READ)"
          :to="{ name: 'RoleList' }"
          icon="/icons/04-Programing-Apps-Websites/05-Bug-Security/security-lock.svg"
          :text="t('lists.navigation.items.roles', 2)"
        />
      </div>
    </Section>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import MenuItem from '@/components/ui/list/ListMenuItem.vue'
import Section from '@/components/ui/list/ListSection.vue'
import { isAuthorized } from '@/utils/isAuthorized'
import { RolePermission } from '@/types/roleType'

const { t } = useI18n()
</script>

<style scoped lang="scss">
.list-navigation {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  &__items {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
}
</style>
