import { defineStore } from 'pinia'
import { type TaskType } from '@/types/taskType'
import TaskService from '@/services/TaskService'

export const useTaskStore = defineStore({
  id: 'tasks',
  state: (): {
    tasks: Map<string, TaskType & { timestamp?: number }>
    listLoaded: boolean
    page: number
    filters?: Record<string, any>
    sortBy?: Record<string, 'ASC' | 'DESC'>
  } => ({
    tasks: new Map<string, TaskType>(),
    listLoaded: false,
    page: 1,
    filters: {
      status: 'active',
    },
    sortBy: undefined,
  }),
  actions: {
    async fetchNextPage(clear?: boolean) {
      if (clear) this.page = 1

      return TaskService.getTasks({
        page: this.page++,
        filters: this.filters,
        order: this.sortBy,
      })
        .then((res) => {
          if (clear) this.tasks.clear()
          return res
        })
        .then(({ data }) =>
          data.forEach((x) => {
            if (!this.tasks.has(x.id)) {
              this.tasks.set(x.id, x)
            }
          }),
        )
        .finally(() => {
          if (this.tasks.size) this.listLoaded = true
        })
    },

    async setFilters(filters: Record<string, any> = {}) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.filters = { ...filters }

        await this.fetchNextPage(true)
      }
    },

    async setSorting(sortBy?: { field: string, direction: 'ASC' | 'DESC' }) {
      if (sortBy !== this.sortBy) {
        this.sortBy = sortBy ? { [sortBy.field]: sortBy.direction } : undefined

        await this.fetchNextPage(true)
      }
    },

    async fetchTaskById(id: string, cached = true) {
      const local = this.tasks.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime())
      ) {
        const { data } = await TaskService.getTaskById(id)

        if (data) {
          this.tasks.set(id, {
            ...data,
            timestamp: new Date().getTime(),
          })

          if (!cached) this.pushTaskToTop(id)
        }

        return data
      }

      return local
    },

    pushTaskToTop(id: string) {
      const currentTask = this.tasks.get(id)
      const currentList = Array.from<TaskType>(this.tasks.values())

      if (currentTask && currentList) {
        this.tasks.clear()

        this.tasks.set(id, currentTask)

        currentList.forEach((x) => {
          if (x.id !== currentTask.id) {
            this.tasks.set(x.id, x)
          }
        })
      }
    },

    async updateTask(task: TaskType) {
      const { data } = await TaskService.putTask(task)

      this.tasks.set(task.id, data)

      return data
    },

    removeTask(id: string) {
      const local = this.tasks.get(id)

      if (local) this.tasks.delete(id)
    },
  },
  getters: {
    dataAsArray: (state) => Array.from<TaskType>(state.tasks.values()),

    listIsLoaded: (state) => state.listLoaded,

    findOrThrowTaskById: (state) => {
      return (taskId: string) => {
        const task = state.tasks?.get(taskId)

        if (!task) throw new Error('Task not found')

        return task
      }
    },
  },
})
