import { defineStore } from 'pinia'
import ComplaintService from '@/services/ComplaintService'
import {
  type ComplaintPost,
  type ComplaintType,
  ComplaintStatus,
} from '@/types/complaintType'
import type { EntityTypeOf } from '@/types/types'
import { format } from 'date-fns'

export const useComplaintStore = defineStore({
  id: 'complaints',
  state: (): {
    complaints: Map<
      string,
      ComplaintType & { timestamp?: number, outdated?: boolean }
    >
    page: number
    filters?: Record<string, any>
    sortBy?: Record<string, 'ASC' | 'DESC'>
  } => ({
    complaints: new Map<string, ComplaintType>(),
    page: 1,
    filters: undefined,
    sortBy: {
      createdAt: 'DESC',
    },
  }),
  actions: {
    async fetchNextPage(clear?: boolean) {
      if (clear) this.page = 1

      return ComplaintService.getComplaints({
        page: this.page++,
        filters: this.filters,
        order: this.sortBy,
      })
        .then((res) => {
          if (clear) this.complaints.clear()
          return res
        })
        .then(({ data }) => data.forEach((x) => this.complaints.set(x.id, x)))
    },

    async setFilters(filters: Record<string, any> = {}) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.filters = { ...filters }

        await this.fetchNextPage(true)
      }
    },

    async setSorting(sortBy?: { field: string, direction: 'ASC' | 'DESC' }) {
      if (sortBy !== this.sortBy) {
        this.sortBy = sortBy ? { [sortBy.field]: sortBy.direction } : undefined

        await this.fetchNextPage(true)
      }
    },

    async fetchComplaintById(id: string, cached = true) {
      const local = this.complaints.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } = await ComplaintService.getComplaintById(id)

        if (data) {
          this.complaints.set(id, {
            ...(local?.outdated ? local : {}),
            ...data,
            timestamp: new Date().getTime(),
            outdated: false,
          })
        }

        return data
      }

      return local
    },

    async fetchComplaintByEntityId(
      entityId: string,
      entityType: EntityTypeOf.CLIENT | EntityTypeOf.ALLOCATION,
    ) {
      return (
        await ComplaintService.getComplaints({
          filters: {
            [entityType]: entityId,
            pageSize: 999,
          },
        })
      ).data
    },

    async addNewComplaint(complaint: ComplaintPost) {
      const { data } = await ComplaintService.postComplaint(complaint)

      this.complaints.set(data.id, data)

      return data
    },

    async updateComplaint(complaint: ComplaintType) {
      const { data } = await ComplaintService.putComplaint(complaint)

      this.complaints.set(data.id, {
        ...data,
        timestamp: new Date().getTime(),
      })

      return data
    },

    async markComplaintAsOutdated(id: string, watching: boolean) {
      const local = this.complaints.get(id)

      if (local?.timestamp) {
        local.outdated = true

        if (watching) this.fetchComplaintById(id, false)
      }
    },

    // Direct status changes
    async sendToSupplier(id: string) {
      const current = await this.fetchComplaintById(id)

      return await this.updateComplaint({
        ...current,
        complaintStatus: ComplaintStatus.FORWARDED_TO_SUPPLIER,
      })
    },

    async takeUnderReview(id: string) {
      const current = await this.fetchComplaintById(id)

      return await this.updateComplaint({
        ...current,
        complaintStatus: ComplaintStatus.UNDER_REVIEW_BY_SUPPLIER,
        inProgressAt: format(new Date(), 'yyyy-MM-dd'),
      })
    },

    async resolveSatisfied(id: string) {
      const current = await this.fetchComplaintById(id)

      return await this.updateComplaint({
        ...current,
        complaintStatus: ComplaintStatus.RESOLVED_SATISFIED,
      })
    },

    async resolveNotSatisfied(id: string) {
      const current = await this.fetchComplaintById(id)

      return await this.updateComplaint({
        ...current,
        complaintStatus: ComplaintStatus.RESOLVED_NOT_SATISFIED,
      })
    },

    async backToSupplier(id: string) {
      const current = await this.fetchComplaintById(id)

      return await this.updateComplaint({
        ...current,
        complaintStatus: ComplaintStatus.FORWARDED_TO_SUPPLIER,
        completedAt: undefined,
      })
    },

    async ignore(id: string) {
      const current = await this.fetchComplaintById(id)

      return await this.updateComplaint({
        ...current,
        complaintStatus: ComplaintStatus.NOT_TAKEN_INTO_CONSIDERATION,
      })
    },
  },
  getters: {
    complaintsAsArray: (state) =>
      Array.from<ComplaintType>(state.complaints.values()),

    findOrThrowComplaintById: (state) => {
      return (complaintId: string) => {
        const complaint = state.complaints?.get(complaintId)

        if (!complaint) throw new Error('Complaint not found')

        return complaint
      }
    },
  },
})
