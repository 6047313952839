import { defineStore } from 'pinia'
import type {
  MunicipalityContact,
  MunicipalityType,
} from '@/types/municipalityType'
import type { SortBy } from '@/types/types'
import MunicipalityService from '@/services/MunicipalityService'

export const useMunicipalityStore = defineStore({
  id: 'municipalities',
  state: (): {
    data: Map<
      string,
      MunicipalityType & { timestamp?: number, outdated?: boolean }
    >
    page: number
    filters?: Record<string, any>
    sortBy?: Record<string, 'ASC' | 'DESC'>
  } => ({
    data: new Map(),
    page: 1,
    filters: {
      status: 'active',
    },
    sortBy: {
      name: 'ASC',
    },
  }),

  actions: {
    async fetchNextPage(clear?: boolean) {
      if (clear) this.page = 1

      return MunicipalityService.getMunicipalities({
        page: this.page++,
        filters: this.filters,
        order: this.sortBy,
      })
        .then((res) => {
          if (clear) this.data.clear()
          return res
        })
        .then(({ data }) => data.forEach((x) => this.data.set(x.id, x)))
    },

    async setFilters(filters: Record<string, any> = {}) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.filters = { ...filters }

        await this.fetchNextPage(true)
      }
    },

    async setSorting(sortBy?: SortBy) {
      if (sortBy !== this.sortBy) {
        this.sortBy = sortBy ? { [sortBy.field]: sortBy.direction } : undefined

        await this.fetchNextPage(true)
      }
    },

    async getAllMunicipalities() {
      return (
        await MunicipalityService.getMunicipalities({ page: 1, pageSize: 999 })
      ).data
    },

    async addNewMunicipality(municipality: MunicipalityType) {
      const { data } = await MunicipalityService.postMunicipality(municipality)

      this.data.set(data.id, data)

      return data
    },

    async updateMunicipality(municipality: MunicipalityType) {
      const { data } =
        await MunicipalityService.updateMunicipality(municipality)

      this.data.set(municipality.id, data)

      return data
    },

    async addMunicipalityContact(
      municipalityId: string,
      contact: Omit<MunicipalityContact, 'id'>,
    ) {
      const { data } = await MunicipalityService.postMunicipalityContact(
        municipalityId,
        contact,
      )

      const current = this.data.get(municipalityId)

      if (current)
        this.data.set(municipalityId, {
          ...current,
          municipalityContacts: [...(current.municipalityContacts ?? []), data],
        })

      return data
    },

    async updateMunicipalityContact(
      municipalityId: string,
      contact: MunicipalityContact,
    ) {
      const { data } = await MunicipalityService.updateMunicipalityContact(
        municipalityId,
        contact,
      )

      const current = this.data.get(municipalityId)

      if (current)
        this.data.set(municipalityId, {
          ...current,
          municipalityContacts: current.municipalityContacts?.map((c) =>
            c.id === data.id ? data : c,
          ),
        })

      return data
    },

    async deleteMunicipalityContact(municipalityId: string, contactId: string) {
      await MunicipalityService.deleteMunicipalityContact(
        municipalityId,
        contactId,
      )

      const current = this.data.get(municipalityId)

      if (current)
        this.data.set(municipalityId, {
          ...current,
          municipalityContacts: current.municipalityContacts?.filter(
            (c) => c.id !== contactId,
          ),
        })
    },

    async fetchMunicipalityById(id: string, cached = true) {
      const local = this.data.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } = await MunicipalityService.getMunicipalityById(id)

        this.data.set(id, {
          ...(local?.outdated ? local : {}),
          ...data,
          timestamp: new Date().getTime(),
          outdated: false,
        })

        return data
      }

      return local
    },

    async markMunicipalityAsOutdated(id: string, watching: boolean) {
      const local = this.data.get(id)

      if (local?.timestamp) {
        local.outdated = true

        if (watching) this.fetchMunicipalityById(id, false)
      }
    },
  },
  getters: {
    dataAsArray: (state) => Array.from<MunicipalityType>(state.data.values()),

    findOrThrowMunicipalityById: (state) => {
      return (id: string) => {
        const municipality = state.data.get(id)

        if (!municipality) throw new Error()

        return municipality
      }
    },

    findOrThrowMunicipalityContactById: (state) => {
      return (municipalityId: string, contactId: string) => {
        const contact = state.data
          .get(municipalityId)
          ?.municipalityContacts?.find((x) => x.id === contactId)

        if (!contact) throw new Error()

        return contact
      }
    },
  },
})
