import { defineStore } from 'pinia'
import type { AccountingFlat, AccountingType } from '@/types/accountingType'
import AccountingService from '@/services/AccountingService'

export const useAccountingStore = defineStore({
  id: 'accountancies',
  state: (): {
    data: Map<
      string,
      AccountingType & { timestamp?: number, outdated?: boolean }
    >
    page: number
    filters?: Record<string, any>
    sortBy?: Record<string, 'ASC' | 'DESC'>
  } => ({
    data: new Map<string, AccountingType>(),
    page: 1,
    filters: undefined,
    sortBy: undefined,
  }),
  actions: {
    async fetchNextPage(clear?: boolean) {
      if (clear) this.page = 1

      return AccountingService.getAccountings({
        page: this.page++,
        filters: this.filters,
        order: this.sortBy,
      })
        .then((res) => {
          if (clear) this.data.clear()
          return res
        })
        .then(({ data }) => data.forEach((x) => this.data.set(x.id, x)))
    },

    async setFilters(filters: Record<string, any> = {}) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.filters = { ...filters }

        await this.fetchNextPage(true)
      }
    },

    async setSorting(sortBy?: { field: string, direction: 'ASC' | 'DESC' }) {
      if (sortBy !== this.sortBy) {
        this.sortBy = sortBy ? { [sortBy.field]: sortBy.direction } : undefined

        await this.fetchNextPage(true)
      }
    },

    async fetchAccountingById(id: string, cached = true) {
      const local = this.data.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } = await AccountingService.getAccountingById(id)

        if (data) {
          this.data.set(id, {
            ...(local?.outdated ? local : {}),
            ...data,
            timestamp: new Date().getTime(),
            outdated: false,
          })
        }

        return data
      }

      return local
    },

    async updateAccounting(accounting: AccountingFlat) {
      const { data } = await AccountingService.putAccounting(accounting)

      this.data.set(accounting.id, data)

      return data
    },

    async addNewAccounting(accounting: AccountingFlat) {
      const { data } = await AccountingService.postAccounting(accounting)

      this.data.set(accounting.id, data)

      return data
    },

    async deleteAccounting(id: string) {
      await AccountingService.deleteAccounting(id)

      this.data.delete(id)
    },

    async getCombos(municipalityId: string) {
      return (await AccountingService.getCombos(municipalityId)).data
    },

    async markAccountingAsOutdated(id: string, watching: boolean) {
      const local = this.data.get(id)

      if (local?.timestamp) {
        local.outdated = true

        if (watching) this.fetchAccountingById(id, false)
      }
    },
  },
  getters: {
    dataAsArray: (state) => Array.from<AccountingType>(state.data.values()),

    findOrThrowAccountingById: (state) => {
      return (id: string) => {
        const accounting = state.data?.get(id)

        if (!accounting) throw new Error('Not found')

        return accounting
      }
    },
  },
})
