import { defineStore } from 'pinia'
import type {
  ContractType,
  ContractTypeFlat,
  ContractExclusion,
  ContractPriceFlat,
} from '@/types/contractType'
import ContractService from '@/services/ContractService'

export const useContractStore = defineStore({
  id: 'contracts',
  state: (): {
    data: Map<string, ContractType & { timestamp?: number, outdated?: boolean }>
    page: number
    filters?: Record<string, any>
    sortBy?: Record<string, 'ASC' | 'DESC'>
  } => ({
    data: new Map(),
    page: 1,
    filters: {
      status: 'active',
    },
    sortBy: undefined,
  }),
  actions: {
    async fetchNextPage(clear?: boolean) {
      if (clear) this.page = 1

      return ContractService.getContracts({
        page: this.page++,
        filters: this.filters,
        order: this.sortBy,
      })
        .then((res) => {
          if (clear) this.data.clear()
          return res
        })
        .then(({ data }) => data.forEach((x) => this.data.set(x.id, x)))
    },

    async setFilters(filters: Record<string, any> = {}) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.filters = { ...filters }

        await this.fetchNextPage(true)
      }
    },

    async setSorting(sortBy?: { field: string, direction: 'ASC' | 'DESC' }) {
      if (sortBy !== this.sortBy) {
        this.sortBy = sortBy ? { [sortBy.field]: sortBy.direction } : undefined

        await this.fetchNextPage(true)
      }
    },

    async addNewContract(contract: ContractTypeFlat) {
      return (await ContractService.postContract(contract)).data
    },

    async updateContract(contract: ContractTypeFlat) {
      const data = (await ContractService.putContract(contract)).data

      this.data.set(contract.id, data)

      return data
    },

    async deleteContract(contractId: string) {
      await ContractService.deleteContract(contractId)

      this.data.delete(contractId)
    },

    async addNewContractExclusion(
      contractId: string,
      exclusion: Omit<ContractExclusion, 'id'>,
    ) {
      const { data } = await ContractService.postContractExclusion(
        contractId,
        exclusion,
      )

      const current = this.data.get(contractId)

      if (current)
        this.data.set(contractId, {
          ...current,
          exclusions: [...(current.exclusions ?? []), data],
        })

      return data
    },

    async updateContractExclusion(
      contractId: string,
      exclusion: ContractExclusion,
    ) {
      const { data } = await ContractService.putContractExclusion(
        contractId,
        exclusion,
      )

      const current = this.data.get(contractId)

      if (current)
        this.data.set(contractId, {
          ...current,
          exclusions: current.exclusions.map((e) =>
            e.id === data.id ? data : e,
          ),
        })

      return data
    },

    async removeContractExclusion(
      contractId: string,
      contractExclusionId: string,
    ) {
      await ContractService.deleteContractExclusion(
        contractId,
        contractExclusionId,
      )

      const current = this.data.get(contractId)

      if (current)
        this.data.set(contractId, {
          ...current,
          exclusions: current.exclusions.filter(
            (c) => c.id !== contractExclusionId,
          ),
        })
    },

    async addNewContractPrice(
      contractId: string,
      contractPrice: ContractPriceFlat,
    ) {
      const { data } = await ContractService.postContractPrice(
        contractId,
        contractPrice,
      )

      const current = this.data.get(contractId)

      if (current)
        this.data.set(contractId, {
          ...current,
          prices: [...current.prices, data],
        })

      return data
    },

    async updateContractPrice(
      contractId: string,
      contractPrice: ContractPriceFlat,
    ) {
      const { data } = await ContractService.putContractPrice(
        contractId,
        contractPrice,
      )

      const current = this.data.get(contractId)

      if (current)
        this.data.set(contractId, {
          ...current,
          prices: [...current.prices, data],
        })

      return data
    },

    async deleteContractPrice(
      contractId: string,
      contractPriceId: string,
      productId: string,
    ) {
      await ContractService.deleteContractPrice(contractId, contractPriceId)

      const current = this.data.get(contractId)

      const price = this.data
        ?.get(contractId)
        ?.prices.find((x) => x.id === productId)
        ?.prices.find((x) => x.contractPriceId === contractPriceId)

      if (price) {
        price.contractPrice = undefined
        price.contractPriceId = undefined
      }

      if (current) {
        this.data.set(contractId, {
          ...current,
          prices: [...current.prices],
        })
      }
    },

    async fetchContractById(id: string, cached = true) {
      const local = this.data.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } = await ContractService.getContractById(id)

        this.data.set(id, {
          ...(local?.outdated ? local : {}),
          ...data,
          timestamp: new Date().getTime(),
          outdated: false,
        })

        return data
      }

      return local
    },

    async fetchContractExclusionById(
      contractId: string,
      exclusionId: string,
      cached = true,
    ) {
      const local = this.data
        .get(contractId)
        ?.exclusions.find((x) => x.id === contractId)

      if (!cached || !local) {
        const { data } = await ContractService.getContractExclusionById(
          contractId,
          exclusionId,
        )

        return data
      }

      return local
    },

    async fetchPricesByContractId(contractId: string) {
      const { data } = await ContractService.getPricesByContractId(contractId)

      const current = this.data.get(contractId)

      if (current)
        this.data.set(contractId, {
          ...current,
          prices: data,
        })

      return data
    },

    async markContractAsOutdated(id: string, watching: boolean) {
      const local = this.data.get(id)

      if (local?.timestamp) {
        local.outdated = true

        if (watching) this.fetchContractById(id, false)
      }
    },
  },
  getters: {
    dataAsArray: (state) => Array.from<ContractType>(state.data.values()),

    findOrThrowContractById: (state) => {
      return (id: string) => {
        const contract = state.data.get(id)

        if (!contract) throw Error()

        return contract
      }
    },

    findOrThrowPricesByContractId: (state) => {
      return (id: string) => {
        const prices = state.data.get(id)?.prices

        if (!prices) throw new Error()

        return prices
      }
    },

    findOrThrowContractPriceById: (state) => {
      return (
        contractId: string,
        productId: string,
        contractPriceId: string,
      ) => {
        const price = state.data
          ?.get(contractId)
          ?.prices.find((x) => x.id === productId)
          ?.prices.find((x) => x.contractPriceId === contractPriceId)

        if (!price) throw new Error('Not found')

        return price
      }
    },

    findOrThrowContractExclusionById: (state) => {
      return (contractId: string, exclusionId: string) => {
        const exclusion = state.data
          .get(contractId)
          ?.exclusions.find((x) => x.id === exclusionId)

        if (!exclusion) throw Error()

        return exclusion
      }
    },
  },
})
