import axios from 'axios'
import type { RoleType, RoleTypeFlat } from '@/types/roleType'

export default class {
  static async getRoles({
    page = 1,
    pageSize = 30,
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<RoleType[]>('roles', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static async getRoleById(id: string) {
    return axios.get<RoleType>(`roles/${id}`)
  }

  static async postRole(role: RoleTypeFlat) {
    return axios.post<RoleType>(`roles`, role)
  }

  static async putRole(role: RoleTypeFlat) {
    return axios.put<RoleType>(`roles/${role.id}`, role)
  }

  static async deleteRole(roleId: string) {
    return axios.delete(`roles/${roleId}`)
  }
}
