<template>
  <router-link
    class="chatitem"
    :class="{
      'chatitem--unread': chat.hasUnreadMessages,
      'chatitem--in-communication-tab': inCommunicationTab,
      'router-link-active--custom': route.query.ChatId === chat.id,
    }"
    :to="
      inCommunicationTab
        ? { query: { ...query, ChatId: chat.id } }
        : { name: 'ChatDetail', params: { id: chat.id } }
    "
  >
    <div class="chatitem__header">
      <span class="chatitem__title">{{ chat.title }}</span>
      <span class="chatitem__date">
        {{
          dateDistanceToNow(new Date(chat.messages[0].timestamp).toISOString())
        }}
      </span>
    </div>
    <div
      v-for="i in 2"
      :key="i"
      class="chatitem__subtitle"
    >
      {{ plainMessage(i - 1) }}
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import MarkDownIt from 'markdown-it'
import plainText from 'markdown-it-plain-text'
import { type ChatType } from '@/types/chatType'
import { dateDistanceToNow } from '@/utils/utils'

const md = new MarkDownIt()
md.use(plainText)

const props = defineProps<{
  chat: ChatType
  inCommunicationTab?: boolean
}>()

const plainMessage = (i: number) =>
  computed(() => {
    if (props.chat.messages[i]) {
      md.render(props.chat.messages[i].message)
      return (md as any).plainText
    }
    return undefined
  })

const route = useRoute()
const query = ref()

setTimeout(() => {
  query.value = route.query
}, 100)
</script>

<style scoped lang="scss">
.chatitem {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  background-color: transparent;
  padding: 1.2rem 1.2rem 1.2rem 2.4rem;
  color: var(--color-light);
  cursor: pointer;
  border-radius: 0.4rem;

  &.router-link-active {
    background-color: var(--color-primary);
  }

  &:hover {
    background-color: var(--color-primary-80);
    text-decoration: none;
  }

  &:active {
    outline: 1px solid var(--color-primary-70);
  }

  &--unread {
    &::before {
      content: '';
      background-color: var(--color-light);
      position: absolute;
      top: 1.6rem;
      left: 1.2rem;
      width: 8px;
      height: 8px;
      border-radius: 5px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }

  &__title {
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &__date {
    text-align: right;
    color: var(--color-background-60);
  }

  &__subtitle {
    color: var(--color-light);
    font-size: 1.2rem;
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &--in-communication-tab {
    width: 100%;
    color: var(--color-dark-80);

    &:hover {
      background-color: var(--color-primary-5);
      text-decoration: none;
    }

    .chatitem {
      &__subtitle {
        color: var(--color-dark-80);
      }
    }

    &.chatitem {
      &--unread {
        &::before {
          background-color: var(--color-primary);
        }
      }
    }

    &.router-link-active {
      background-color: transparent;

      &--custom {
        background-color: var(--color-primary-20);
      }
    }
  }
}
</style>
