import axios from 'axios'
import type { ClaimType } from '@/types/claimType'

export default class {
  static async getClaims({
    page = 1,
    pageSize = 30,
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<ClaimType[]>('claims', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static async getClaimById(id: string) {
    return axios.get<ClaimType>(`claims/${id}`)
  }
}
