import axios from 'axios'
import type {
  MunicipalityContact,
  MunicipalityType,
} from '@/types/municipalityType'

export default class {
  static getMunicipalities({
    page = 1,
    pageSize = 30,
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<MunicipalityType[]>('municipalities', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static getMunicipalityById(id: string) {
    return axios.get<MunicipalityType>(`municipalities/${id}`)
  }

  static async postMunicipality(municipality: MunicipalityType) {
    return axios.post<MunicipalityType>(`municipalities`, municipality)
  }

  static updateMunicipality(municipality: MunicipalityType) {
    return axios.put<MunicipalityType>(
      `municipalities/${municipality.id}`,
      municipality,
    )
  }

  static getMunicipalityContactById(municipalityId: string, contactId: string) {
    return axios.get<MunicipalityContact>(
      `municipalities/${municipalityId}/municipality_contacts/${contactId}`,
    )
  }

  static postMunicipalityContact(
    municipalityId: string,
    contact: Omit<MunicipalityContact, 'id'>,
  ) {
    return axios.post<MunicipalityContact>(
      `municipalities/${municipalityId}/municipality_contacts`,
      contact,
    )
  }

  static updateMunicipalityContact(
    municipalityId: string,
    contact: MunicipalityContact,
  ) {
    return axios.put<MunicipalityContact>(
      `municipalities/${municipalityId}/municipality_contacts/${contact.id}`,
      contact,
    )
  }

  static deleteMunicipalityContact(municipalityId: string, contactId: string) {
    return axios.delete(
      `municipalities/${municipalityId}/municipality_contacts/${contactId}`,
    )
  }
}
