import { defineStore } from 'pinia'
import type { PaymentType, PaymentHistoryType } from '@/types/paymentType'
import PaymentService from '@/services/PaymentService'
import { AccountingSoftware } from '@/types/municipalityType'

export const usePaymentStore = defineStore({
  id: 'payments',
  state: (): {
    downloads: Map<string, PaymentType & { timestamp?: number }>
    history: Map<string, PaymentHistoryType & { timestamp?: number }>

    downloadPage: number
    historyPage: number
  } => ({
    downloads: new Map<string, PaymentType>(),
    history: new Map<string, PaymentHistoryType>(),

    downloadPage: 1,
    historyPage: 1,
  }),
  actions: {
    async fetchNextPage(clear?: boolean) {
      if (clear) this.downloadPage = 1

      return PaymentService.getPayments({
        page: this.downloadPage++,
      })
        .then((res) => {
          if (clear) this.downloads.clear()
          return res
        })
        .then(({ data }) =>
          data.forEach((x) => this.downloads.set(x.municipality.id, x)),
        )
    },

    async fetchNextHistoryPage(clear?: boolean) {
      if (clear) this.historyPage = 1

      return PaymentService.getDownloadHistory({
        page: this.historyPage++,
      })
        .then((res) => {
          if (clear) this.history.clear()
          return res
        })
        .then(({ data }) => data.forEach((x) => this.history.set(x.id, x)))
    },

    async fetchPaymentByMunicipalityAndSoftware(
      municipalityId: string,
      accountingSoftware: AccountingSoftware,
    ) {
      const { data } = await PaymentService.getPaymentByMunicipalityAndSoftware(
        municipalityId,
        accountingSoftware,
      )

      if (data) this.downloads.delete(municipalityId)

      return data
    },
  },
  getters: {
    downloadsAsArray: (state) =>
      Array.from<PaymentType>(state.downloads.values()),

    historyAsArray: (state) =>
      Array.from<PaymentHistoryType>(state.history.values()),
  },
})
