import { defineStore } from 'pinia'
import type { CreditorFlat, CreditorType } from '@/types/creditorType'
import CreditorService from '@/services/CreditorService'

export const useCreditorStore = defineStore({
  id: 'creditors',
  state: (): {
    data: Map<string, CreditorType & { timestamp?: number, outdated?: boolean }>
    page: number
    filters?: Record<string, any>
    sortBy?: Record<string, 'ASC' | 'DESC'>
  } => ({
    data: new Map<string, CreditorType>(),
    page: 1,
    filters: undefined,
    sortBy: undefined,
  }),
  actions: {
    async fetchNextPage(clear?: boolean) {
      if (clear) this.page = 1

      return CreditorService.getCreditors({
        page: this.page++,
        filters: this.filters,
        order: this.sortBy,
      })
        .then((res) => {
          if (clear) this.data.clear()
          return res
        })
        .then(({ data }) => data.forEach((x) => this.data.set(x.id, x)))
    },

    async setFilters(filters: Record<string, any> = {}) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.filters = { ...filters }

        await this.fetchNextPage(true)
      }
    },

    async setSorting(sortBy?: { field: string, direction: 'ASC' | 'DESC' }) {
      if (sortBy !== this.sortBy) {
        this.sortBy = sortBy ? { [sortBy.field]: sortBy.direction } : undefined

        await this.fetchNextPage(true)
      }
    },

    async fetchCreditorById(id: string, cached = true) {
      const local = this.data.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } = await CreditorService.getCreditorById(id)

        if (data) {
          this.data.set(id, {
            ...data,
            timestamp: new Date().getTime(),
          })
        }

        return data
      }

      return local
    },

    async updateCreditor(creditor: CreditorFlat) {
      const { data } = await CreditorService.putCreditor(creditor)

      this.data.set(creditor.id, data)

      return data
    },

    async addNewCreditor(creditor: CreditorFlat) {
      const { data } = await CreditorService.postCreditor(creditor)

      this.data.set(creditor.id, data)

      return data
    },

    async deleteCreditor(id: string) {
      await CreditorService.deleteCreditor(id)

      this.data.delete(id)
    },

    async markCreditorAsOutdated(id: string, watching: boolean) {
      const local = this.data.get(id)

      if (local?.timestamp) {
        local.outdated = true

        if (watching) this.fetchCreditorById(id, false)
      }
    },
  },
  getters: {
    dataAsArray: (state) => Array.from<CreditorType>(state.data.values()),

    findOrThrowCreditorById: (state) => {
      return (id: string) => {
        const creditor = state.data?.get(id)

        if (!creditor) throw new Error('Not found')

        return creditor
      }
    },
  },
})
