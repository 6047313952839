import axios from 'axios'
import type {
  IberichtHistory,
  IberichtType,
  IberichtTypeOf,
} from '@/types/iberichtType'
import type { FileType } from '@/types/fileType'

export default class {
  static async getIberichts({
    page = 1,
    pageSize = 30,
    filters = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
  } = {}) {
    return axios.get<IberichtType[]>('iberichten/download', {
      params: { ...filters, page, itemsPerPage: pageSize },
    })
  }

  static async getDownloadHistory({
    page = 1,
    pageSize = 30,
    filters = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
  } = {}) {
    return axios.get<IberichtHistory[]>('iberichten/history', {
      params: { ...filters, page, itemsPerPage: pageSize },
    })
  }

  static async getIberichtByMunicipalityAndType(
    municipalityId: string,
    type: IberichtTypeOf,
  ) {
    return axios.get<FileType>(`iberichten/download/${municipalityId}/${type}`)
  }
}
